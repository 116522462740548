import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import * as i2 from '@ngx-formly/core';
import { FormlyModule } from '@ngx-formly/core';
import * as i3 from '@ngx-formly/core/select';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FieldType, FormlyBootstrapFormFieldModule } from '@ngx-formly/bootstrap/form-field';
const _c0 = (a0, a1) => ({
  "form-check-inline": a0,
  "form-switch": a1
});
function FormlyFieldMultiCheckbox_ng_template_0_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2)(1, "input", 3);
    i0.ɵɵlistener("change", function FormlyFieldMultiCheckbox_ng_template_0_div_0_Template_input_change_1_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r6);
      const option_r3 = restoredCtx.$implicit;
      const ctx_r5 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r5.onChange(option_r3.value, $event.target.checked));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "label", 4);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const option_r3 = ctx.$implicit;
    const i_r4 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(12, _c0, ctx_r2.props.formCheck === "inline" || ctx_r2.props.formCheck === "inline-switch", ctx_r2.props.formCheck === "switch" || ctx_r2.props.formCheck === "inline-switch"));
    i0.ɵɵadvance();
    i0.ɵɵclassProp("is-invalid", ctx_r2.showError);
    i0.ɵɵproperty("id", ctx_r2.id + "_" + i_r4)("value", option_r3.value)("checked", ctx_r2.isChecked(option_r3))("formlyAttributes", ctx_r2.field)("disabled", ctx_r2.formControl.disabled || option_r3.disabled);
    i0.ɵɵattribute("aria-describedby", ctx_r2.id + "-formly-validation-error")("aria-invalid", ctx_r2.showError);
    i0.ɵɵadvance();
    i0.ɵɵproperty("for", ctx_r2.id + "_" + i_r4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r3.label, " ");
  }
}
function FormlyFieldMultiCheckbox_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, FormlyFieldMultiCheckbox_ng_template_0_div_0_Template, 4, 15, "div", 1);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵpipe(2, "formlySelectOptions");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(1, 1, i0.ɵɵpipeBind2(2, 3, ctx_r0.props.options, ctx_r0.field)));
  }
}
class FormlyFieldMultiCheckbox extends FieldType {
  constructor() {
    super(...arguments);
    this.defaultOptions = {
      props: {
        formCheck: 'default' // 'default' | 'inline' | 'switch' | 'inline-switch'
      }
    };
  }
  onChange(value, checked) {
    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(checked ? [...(this.formControl.value || []), value] : [...(this.formControl.value || [])].filter(o => o !== value));
    } else {
      this.formControl.patchValue({
        ...this.formControl.value,
        [value]: checked
      });
    }
    this.formControl.markAsTouched();
  }
  isChecked(option) {
    const value = this.formControl.value;
    return value && (this.props.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value]);
  }
}
FormlyFieldMultiCheckbox.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldMultiCheckbox_BaseFactory;
  return function FormlyFieldMultiCheckbox_Factory(t) {
    return (ɵFormlyFieldMultiCheckbox_BaseFactory || (ɵFormlyFieldMultiCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldMultiCheckbox)))(t || FormlyFieldMultiCheckbox);
  };
})();
FormlyFieldMultiCheckbox.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldMultiCheckbox,
  selectors: [["formly-field-multicheckbox"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["fieldTypeTemplate", ""], ["class", "form-check", 3, "ngClass", 4, "ngFor", "ngForOf"], [1, "form-check", 3, "ngClass"], ["type", "checkbox", 1, "form-check-input", 3, "id", "value", "checked", "formlyAttributes", "disabled", "change"], [1, "form-check-label", 3, "for"]],
  template: function FormlyFieldMultiCheckbox_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldMultiCheckbox_ng_template_0_Template, 3, 6, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [i1.NgForOf, i1.NgClass, i2.ɵFormlyAttributes, i1.AsyncPipe, i3.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldMultiCheckbox, [{
    type: Component,
    args: [{
      selector: 'formly-field-multicheckbox',
      template: `
    <ng-template #fieldTypeTemplate>
      <div
        *ngFor="let option of props.options | formlySelectOptions : field | async; let i = index"
        class="form-check"
        [ngClass]="{
          'form-check-inline': props.formCheck === 'inline' || props.formCheck === 'inline-switch',
          'form-switch': props.formCheck === 'switch' || props.formCheck === 'inline-switch'
        }"
      >
        <input
          type="checkbox"
          [id]="id + '_' + i"
          class="form-check-input"
          [class.is-invalid]="showError"
          [value]="option.value"
          [checked]="isChecked(option)"
          [formlyAttributes]="field"
          [disabled]="formControl.disabled || option.disabled"
          [attr.aria-describedby]="id + '-formly-validation-error'"
          [attr.aria-invalid]="showError"
          (change)="onChange(option.value, $any($event.target).checked)"
        />
        <label class="form-check-label" [for]="id + '_' + i">
          {{ option.label }}
        </label>
      </div>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyBootstrapMultiCheckboxModule {}
FormlyBootstrapMultiCheckboxModule.ɵfac = function FormlyBootstrapMultiCheckboxModule_Factory(t) {
  return new (t || FormlyBootstrapMultiCheckboxModule)();
};
FormlyBootstrapMultiCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyBootstrapMultiCheckboxModule
});
FormlyBootstrapMultiCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
    types: [{
      name: 'multicheckbox',
      component: FormlyFieldMultiCheckbox,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyBootstrapMultiCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldMultiCheckbox],
      imports: [CommonModule, ReactiveFormsModule, FormlyBootstrapFormFieldModule, FormlySelectModule, FormlyModule.forChild({
        types: [{
          name: 'multicheckbox',
          component: FormlyFieldMultiCheckbox,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyBootstrapMultiCheckboxModule, FormlyFieldMultiCheckbox };
